// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyBQ7Fki3Wdnk0vwi3GZqgI_YNxvy6JlxB8",
  authDomain: "pillager-app.firebaseapp.com",
  projectId: "pillager-app",
  storageBucket: "pillager-app.appspot.com",
  messagingSenderId: "912289851478",
  appId: "1:912289851478:web:a97c03a9de64b6305873f7",
  measurementId: "G-K65LPR76D0",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
