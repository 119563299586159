import logo from "./logo.png";
import "./App.css";
import "./firebase-init";

function App() {
  return (
    <div className="App">
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
          padding: "10px",
          fontSize: "1.2rem",
        }}
      >
        <a
          className="App-link"
          href="https://www.loom.com/share/69f9b4bdc851402e8eba3d8de1aae2a7?sid=59232616-ebf7-4497-9aff-ccfb1489d880"
          target="_blank"
          rel="noopener noreferrer"
          style={{ textDecoration: "none" }}
        >
          ▶ Watch Tutorial
        </a>
        <a
          className="App-link"
          href="https://twitter.com/0xterran"
          target="_blank"
          rel="noopener noreferrer"
          style={{ textDecoration: "none" }}
        >
          Request Plugin
        </a>
      </div>
      <header className="App-header">
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            width: "100%",
            maxWidth: "1000px",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "center",
              width: "100%",
            }}
          >
            <img src={logo} className="App-logo" alt="logo" />
            <h1>Pillager</h1>
          </div>
          <p style={{ textAlign: "left", fontSize: "1.5rem" }}>
            A library of code snippets for casual web scraping. Built by an open
            source community.
          </p>
        </div>
      </header>
      <br />
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "center",
          padding: "10px",
          fontSize: "1.2rem",
        }}
      >
        <iframe
          title="airtable"
          class="airtable-embed"
          src="https://airtable.com/embed/app4ZnkgWGFB1n2gs/shriZ8sHv5ZcTKPFj?backgroundColor=orange&viewControls=on"
          frameborder="0"
          onmousewheel=""
          width="100%"
          height="533"
          style={{
            background: "transparent",
            border: "0px solid #ccc",
            maxWidth: "90vw",
            height: "100%",
            minHeight: "90vh",
          }}
        ></iframe>
      </div>
      <br />
      <br />
      <br />
      <div style={{ color: "gray", fontWeight: "bold" }}>
        <span>Copyright 2024 Pillager App</span>
        <br />
        <span>Contact us entropy.internet.01@gmail.com</span>
      </div>
      <br />
      <br />
      <br />
    </div>
  );
}

export default App;
